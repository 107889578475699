<template>
  <aside>
    <v-container class="pt-8 pt-lg-15 pb-0 pb-sm-9 pb-lg-12 text-center">
      <p class="mb-0 mb-sm-3">{{ title }}</p>
      
      <nav class="d-inline-flex align-center justify-center rounded-lg" aria-label="현재 위치">
        <ol>
          <li>
            <v-btn
              depressed
              tile
              :width="buttonHeight"
              :min-width="buttonHeight"
              :height="buttonHeight"
              color="primary"
              to="/"
              class="pa-0 rounded-l-lg">
              <v-icon>mdi-home</v-icon>
              <span class="d-sr-only">홈으로 이동</span>
            </v-btn>
          </li>
          <li id="menu-depth-1">
            <v-menu
              :min-width="menuWidth"
              content-class="rounded-0"
              attach="#menu-depth-1">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  block
                  tile
                  depressed
                  :height="buttonHeight"
                  color="white"
                  class="px-2 px-lg-5"
                  :class="{'rounded-r-lg': 1 >= navigations.length }">
                  <template v-slot:default>
                    <div class="flex-grow-1 text-left">{{ title }}</div>
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-btn>
              </template>
              <v-list class="pa-0">
                <v-list-item
                  v-for="nav in GNB"
                  :key="nav.name"
                  :to="nav.path"
                  class="px-2 px-lg-5"
                  :aria-current="nav.path == $route.redirectedFrom ? 'location':null">
                  <v-list-item-content class="pa-0">
                    <v-list-item-title>{{ nav.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </li>
          <li id="menu-depth-2" v-if="navigations.length > 1">
            <v-menu
              :min-width="menuWidth"
              content-class="rounded-0 rounded-tr-lg"
              attach="#menu-depth-2">
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  block
                  tile
                  depressed
                  :height="buttonHeight"
                  color="white"
                  class="px-2 px-lg-5 rounded-r-lg">
                  <template v-slot:default>
                    <div class="flex-grow-1 text-left text-overflow">{{ subtitle }}</div>
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-btn>
              </template>
              <v-list class="pa-0">
                <v-list-item
                  v-for="nav in navigations"
                  :key="nav.name"
                  :to="nav.path"
                  class="px-2 px-lg-5"
                  :aria-current="nav.path == $route.fullPath ? 'location':null">
                  <v-list-item-content class="pa-0">
                    <v-list-item-title>{{ nav.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </li>
        </ol>
      </nav>
    </v-container>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'GNB'
    ]),
    title() {
      return this.$route.matched[0].name
    },
    subtitle() {
      if(this.$route.name) return this.$route.name
      else {
        const max = this.$route.matched.length
        const route = this.$route.matched[max - 1]
        return route.parent.name
      }
    },
    navigations() {
      let _index;
      this.$router.options.routes.forEach((el, index) => {
        if(this.$route.matched[0].path == el.path) _index = index
      })

      const isDepth = this.$router.options.routes[_index].meta.isDepth
      if(isDepth) {
        return this.$router.options.routes[_index].children.map(el => {
          if(el.redirect) return {
            path: el.redirect,
            name: el.name
          }
          else return {
            path: el.path,
            name: el.name
          }
        });

      } else {
        return []
      }
    },
    buttonHeight() {
      return this.$vuetify.breakpoint.lgAndUp ? '50':'35'
    },
    menuWidth() {
      switch(this.$vuetify.breakpoint.name) {
        case 'xs': return '125';
        case 'sm':
        case 'md': return '152';
        default: return '275'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
ol {
  list-style: none;
  display: inline-flex;
  padding: 0;
}
aside {
  background: url('@/assets/home/visual_img.png') no-repeat center center;
  background-size: cover;
  p {
    font-size: 38px;
    font-weight: bold;
    line-height: 55px;
  }
  nav {
    // border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    // overflow: hidden;
    & > ol > li:not(:first-child) {
      width: 275px;
      & + li {
        border-left: solid 1px #D9D9D9;
      }
    }
  }
  .v-btn--block {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.03em;
    &:hover::before {
      opacity: 0;
    }
    .text-overflow {
      width: 85px;
      @media screen and (min-width: 600px) {
        width: 100px;
      }
    }
  }
}
.v-list-item {
  min-height: 50px;
  &.v-list-item--active {
    color: var(--v-primary-base);
    &::before {
      opacity: 0;
    }
  }
  .v-list-item__title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.03em;
  }
}
@media screen and (max-width: 1179px) {
  aside {
    p {
      font-size: 28px;
      line-height: 40px;
    }
    nav > ol > li:not(:first-child) {
      width: 125px;
      @media screen and (min-width: 600px) {
        width: 152px;
      }
    }
    .v-btn--block {
      font-size: 14px;
    }
  }
  .v-list-item {
    min-height: 35px;
    .v-list-item__title{
      font-size: 14px;
      line-height: 20px;
    }
  }
}
@media screen and (max-width: 600px) {
  aside nav {
    position: relative;
    bottom: -17px;
  }
}
</style>