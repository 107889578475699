<template>
  <v-app-bar
    app
    :clipped-right="!$vuetify.breakpoint.lgAndUp"
    :height="$vuetify.breakpoint.lgAndUp ? '120':'70'"
    color="white">
    <v-container class="d-flex align-center px-0 px-lg-3 py-2">
      <Logo />
      <v-spacer />
      <nav
        v-if="$vuetify.breakpoint.lgAndUp"
        class="nav d-flex align-center"
        aria-label="메인 메뉴">
        <ul class="nav-item">
          <li 
            v-for="nav in GNB"
            :key="nav.name">
            <RouterLink
              :to="nav.path"
              active-class="active"
              class="nav-link"
              :aria-current="nav.path == $route.redirectedFrom ? 'page':null">
              {{ nav.name }}
            </RouterLink>
          </li>
        </ul>
      </nav>

      <v-btn
        v-else
        icon
        :ripple="false"
        @click.stop="toggleDrawer">
        <v-img
          v-if="!isDrawer"
          max-width="35"
          max-height="26"
          src="@/assets/btn_open.svg"
          alt="메뉴 확장" />
        <v-img
          v-else
          max-width="27"
          max-height="27"
          src="@/assets/btn_close.svg"
          alt="메뉴 축소" />
      </v-btn>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '@/components/Logo'

export default {
  components: {
    Logo
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters([
      'GNB'
    ]),
    isDrawer() {
      return this.$store.state.isDrawer
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.commit('SET_DRAWER', !this.isDrawer);
      // console.log('this.isDrawer=', this.isDrawer);
    }
  }
}
</script>

<style lang="scss" scoped>
.v-app-bar {
  box-shadow: 0px 4px 5px 0px #0000001A !important;
  z-index: 10 !important;
}
.nav-item {
  list-style: none;
  display: inline-flex;
}
.nav-link {
  display: inline-block;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.02em;
  text-decoration: none;
  color: black;
  transition: all ease-in-out .2s;

  &.active,
  &:hover {
    color: var(--v-primary-base);
  }
}
</style>