import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'Home',
    component: () => import("@/views/Home/Home.vue")
  },
  {
    path: '/kodsc',
    name: '직업병 의심 사례 제보',
    component: require("@/layout/SubLayout.vue").default,
    redirect: '/kodsc/',
    meta: { isDepth: false },
    children: [
      {
        path: '/kodsc/',
        component: () => import('@/views/Kodsc/KodscGuide.vue')
      }
    ]
  },
  {
    path: '/major',
    name: '주요 직업병',
    component: require("@/layout/SubLayout.vue").default,
    redirect: '/major/GI',
    meta: { isDepth: true },
    children: [
      {
        path: '/major/GI',
        name: '직업성 소화기 질환',
        component: () => import('@/views/Major/MajorGI.vue')
      },
      {
        path: '/major/EY',
        name: '직업성 안질환',
        component: () => import('@/views/Major/MajorEY.vue')
      },
      {
        path: '/major/EM',
        name: '직업성 중독',
        component: () => import('@/views/Major/MajorEM.vue')
      },
      {
        path: '/major/CA',
        name: '직업성 암',
        component: () => import('@/views/Major/MajorCA.vue')
      },
      {
        path: '/major/PUL',
        name: '직업성 호흡기 질병',
        component: () => import('@/views/Major/MajorPUL.vue')
      },
      {
        path: '/major/C',
        name: '직업성 순환기 질병',
        component: () => import('@/views/Major/MajorC.vue')
      },
      {
        path: '/major/DER',
        name: '직업성 피부 질환',
        component: () => import('@/views/Major/MajorDER.vue')
      },
      {
        path: '/major/NM',
        name: '직업성 신경계 질환',
        component: () => import('@/views/Major/MajorNM.vue')
      },
      {
        path: '/major/NEP',
        name: '직업성 신장계 질환',
        component: () => import('@/views/Major/MajorNEP.vue')
      },
      {
        path: '/major/ENT',
        name: '직업성 이비인후과계 질환',
        component: () => import('@/views/Major/MajorENT.vue')
      }
    ]
  },
  {
    path: '/field',
    name: '현장조사',
    component: require("@/layout/SubLayout.vue").default,
    redirect: '/field/',
    meta: { isDepth: false },
    children: [
      {
        path: '/field/',
        component: () => import('@/views/Field/FieldInvest.vue')
      }
    ]
  },
  {
    path: '/consult',
    name: '고용노동청 자문지원',
    component: require("@/layout/SubLayout.vue").default,
    redirect: '/consult/',
    meta: { isDepth: false },
    children: [
      {
        path: '/consult/',
        component: () => import('@/views/Consult/ConsultSupport.vue')
      }
    ]
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: require("@/layout/SubLayout.vue").default,
    redirect: '/faq/od',
    meta: { isDepth: true },
    children: [
      {
        path: '/faq/od',
        name: '직업병 FAQ',
        component: () => import('@/views/Faq/FaqOd.vue')
      },
      {
        path: '/faq/sd',
        name: '중대재해 FAQ',
        component: () => import('@/views/Faq/FaqSd.vue')
      }
    ]
  },
  {
    path: '/board/notice',
    name: '직업병 안심센터 소식',
    component: require("@/layout/SubLayout.vue").default,
    redirect: '/board/notice/list',
    meta: { isDepth: false },
    children: [
      {
        path: '/board/notice/list',
        component: () => import('@/views/Board/Notice/NoticeList.vue')
      },
      {
        path: '/board/notice/view/:id',
        component: () => import('@/views/Board/Notice/NoticeView.vue')
      },  
    ]
  },
  {
    path: '/board/qna',
    name: '문의 게시판',
    component: require("@/layout/SubLayout.vue").default,
    redirect: '/board/qna/list',
    meta: { isDepth: false },
    children: [
      {
        path: '/board/qna/list',
        component: () => import('@/views/Board/Qna/QnaList.vue')
      },
      {
        path: '/board/qna/edit/:id',
        component: () => import('@/views/Board/Qna/QnaEdit.vue')
      },
      {
        path: '/board/qna/auth/:id',
        component: () => import('@/views/Board/Qna/QnaAuth.vue')
      }
    ]
  },
  // {
  //   path: '/board',
  //   name: '소식·문의',
  //   component: require("@/layout/SubLayout.vue").default,
  //   redirect: '/board/notice',
  //   children: [
  //     {
  //       path: '/board/notice',
  //       name: '직업병 안심센터 소식',
  //       component: require("@/views/Board/BoardTemplate.vue").default,
  //       redirect: '/board/notice/list',
  //       children: [
  //         {
  //           path: '/board/notice/list',
  //           component: () => import('@/views/Board/Notice/NoticeList.vue')
  //         },
  //         {
  //           path: '/board/notice/view/:id',
  //           component: () => import('@/views/Board/Notice/NoticeView.vue')
  //         },  
  //       ]
  //     },
  //     {
  //       path: '/board/qna',
  //       name: '문의 게시판',
  //       component: require("@/views/Board/BoardTemplate.vue").default,
  //       redirect: '/board/qna/list',
  //       children: [
  //         {
  //           path: '/board/qna/list',
  //           component: () => import('@/views/Board/Qna/QnaList.vue')
  //         },
  //         {
  //           path: '/board/qna/edit/:id',
  //           component: () => import('@/views/Board/Qna/QnaEdit.vue')
  //         },
  //         {
  //           path: '/board/qna/auth/:id',
  //           component: () => import('@/views/Board/Qna/QnaAuth.vue')
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    path: '*',
    // name: 'Home',
    // component: () => import("@/views/Home/Home.vue")
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  // 페이지 이동 시 상단 포커스
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

export default router