<template>
  <v-navigation-drawer
    v-model="isDrawer"
    fixed
    right
    :clipped="!$vuetify.breakpoint.lgAndUp"
    :width="$vuetify.breakpoint.smAndUp ? '350':'290'"
    :style="'top: 70px;'"
    role="menubar"
    >
    <v-list class="pa-0" role="menu">
      <v-list-item
        v-for="nav in GNB"
        :key="nav.name"
        :to="nav.path"
        role="menuitem"
        class="px-6">
        <v-list-item-content class="py-4">
          <v-list-item-title>{{ nav.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
  }),
  watch: {
    mobile() {
      // lg 사이즈 일때 Drawer 숨김
      if(!this.mobile) this.$store.commit('SET_DRAWER', false)
    }
  },
  computed: {
    ...mapGetters([
      'GNB'
    ]),
    mobile() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    isDrawer: {
      get() {
        return this.$store.state.isDrawer
      },
      set(v) {
        this.$store.state.isDrawer = v
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  background-color: #F6F6F6;
  .v-list-item {
    border-bottom: 1px solid #ECECEC;
    &.v-list-item--active {
      color: var(--v-primary-base);
      &::before {
        opacity: 0;
      }
    }
  }
  .v-list-item__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.02em;
  }
}
</style>