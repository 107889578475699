<template>
  <v-footer
    dark
    class="py-9">
    <v-container class="text-center pa-0">
      COPYRIGHT &copy; {{ thisYear }} KODSC ALL RIGHTS RESERVED.
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  computed: {
    thisYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-footer {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #2D2D2D !important;
}
</style>