<template>
  <v-app>
    <TopNav />
    <SideNav />
    <router-view />
    <Footer />
  </v-app>
</template>

<script>
import Footer from '@/components/Footer'
import TopNav from '@/components/TopNav'
import SideNav from '@/components/SideNav'

export default {
  name: 'App',

  components: {
    Footer,
    TopNav,
    SideNav
  },

  data: () => ({
    //
  }),
};
</script>
