<template>
  <RouterLink to="/">
    <v-img
      alt="KODSC 직업병 안심센터"
      class="shrink"
      contain
      src="@/assets/logo.svg"
      transition="scale-transition"
      :width="$vuetify.breakpoint.lgAndUp ? '162':'100'"
      :height="$vuetify.breakpoint.lgAndUp ? '75':'46'" />
  </RouterLink>
</template>

<script>
export default {
  name: 'LogoComponent'
}
</script>