<template>
  <v-main>
    <LocalNav />
    <v-container class="pt-13 pt-sm-10 pt-lg-16">
      <!-- <Breadcrumbs /> -->
      <router-view />
    </v-container>
  </v-main>
</template>

<script>
// import Breadcrumbs from '@/components/Breadcrumbs'
import LocalNav from '@/components/LocalNav'

export default {
  components: {
    // Breadcrumbs,
    LocalNav
  }
}
</script>