import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          lighten1: '#56A3FF',
          base: '#1E6AC6',
          darken1: '#093E7E'
        }
      }
    }
  }
});
