import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {},
  state: {
    isDrawer: false
  },
  getters: {
    GNB() {
      return router.options.routes.filter((el, index) => {
        if(index > 0 && el.name) return {
          name: el.name,
          path: el.path
        }
      })
    }
  },
  mutations: {
    SET_DRAWER(state, booleanValue) {
      state.isDrawer = booleanValue
    }
  },
  actions: {}
});

export default store;